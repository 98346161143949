import { useRouter } from 'next/router';
import { ContentfulService } from '../lib/contentful';
import Layout from '../components/layout';
import SinglePost from '../components/single_post';


export default function Post({ blog }) {
    const router = useRouter()

    // Todo update this for post
    const postMetaTags = createMetaTags(blog);
    // check if fallback I guess...
    // on the first request will send the fallback
    if (router.isFallback) {
        return <div>Loading...</div>
    }
    return (<Layout
              metaTags={postMetaTags}
              title={blog.title}
            >
              <SinglePost
                blog={blog}
              />
	    </Layout>)
}

/*
Post.getInitialProps = async function fetchEntry({query}) {
    const contentfulService = new ContentfulService();
    const results = await contentfulService.fetchPostBySlug(query);
    const blog = results.items[0].fields;
    return {blog: blog};
}
*/

export async function getStaticPaths() {
    const contentfulService = new ContentfulService();
    const allSlugs = await contentfulService.getAllSlugs();
    return {
	paths: [],
	fallback: true,
    };
}


export async function getStaticProps({ params }) {
    const contentfulService = new ContentfulService();
    const results = await contentfulService.fetchPostBySlug(params.slug);
    const blog = results.items[0].fields;
    return {props: {blog: blog}};
}


function createMetaTags(blog) {
    // Todo handle meta tags
    // here's a proble, this overwrites the the entire default meta object
    // so you might want to put in logic about not passing this
    // if it is missing info
    return {
        title: 'test-meta-title-kat'
    };
}
