import React from "react";
import ReactMarkdown from 'react-markdown';
import { useRouter, useEffect } from 'next/router';

import { HOMEPAGE_URL } from "../lib/constants";
import SocialButtons from "../components/social";
import EmailFooter from "../components/email-footer";
import styled from 'styled-components';


const ArticleText = styled.p`
    font-family: 'Inter', -apple-system, sans-serif;
    font-size: 18px;
    letter-spacing: -.009em;
`;


const renderers = {
    paragraph: ArticleText,
}


export default function SinglePost({blog}) {
    const router = useRouter();
    const shareUrl = HOMEPAGE_URL + router.asPath;

    return (
	    <div className="single-post-container">
	  <div className="post-and-email-wrapper">
          <main>
          <article>
	    <header>
	      <h1 className="article">{blog.title}</h1>
	    </header>
	        <ReactMarkdown className="markdown" source={blog.content} renderers={renderers} />
          </article>
            </main>
	    </div>
          <aside id="social-aside">
            <SocialButtons shareUrl={shareUrl} title={blog.title} />
          </aside>
          <style jsx>{`
              main {
                  order: 1;
                  display: flex;
                  background-color: white;
                  padding-right: 1.75rem;
                  padding-left: 1.75rem;
                  padding-top: 1.75rem;
                  padding-bottom: 1.75rem;
              }

              h1 {
                  margin: 0;
                  font-family: "Calibre";
              }

              @media screen and (min-width: 500px) {
              main {
                  order: 2;
                  display: flex;
                  background-color: white;
                  padding-right: 1.75rem;
                  padding-left: 1.75rem;
                  padding-top: 1.75rem;
                  padding-bottom: 1.75rem;
                  border: none;
              }
              .post-and-email-wrapper {
                  order: 2;
              }

              .single-post-container {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
              }
              @media screen and (min-width: 614px) {
                  .single-post-container {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      width: 70%;
                  }
              }
              aside {
                  order: 2;
                  padding-left: 5rem;
                  padding-right: 5rem;
              }
              @media screen and (min-width: 614px) {
                  aside {
                      display: block;
                      order: 1;
                      width: 15%;
                      padding: 0;
                      margin-left: .75rem;
                      margin-right: .75rem;
                  }
              }
          `}</style>
        </div>
    );
}
